<template>
  <div class="ns-dev">
    <div
      class="page-header d-flex align-items-center justify-content-center"
      style="
        background: url('/img/nearshore/NearshoreDevelopment-header.jpg');
        background-size: cover;
      "
    >
      <div class="viewport">
        <h1>Take advantage of nearshoring development</h1>
        <p>to support and enhance your operations</p>
      </div>
      <div class="overlay"></div>
    </div>
    <div class="viewport">
      <div class="content">
        <p>
          Whether you need just one or two new staff or an entire team of developers, QA specialists
          and project managers, we’ll help you identify the necessary roles and fill them with the
          most suitable people. Everything we offer has been designed to make things easier for our
          clients, with suggested agile methodologies to build your projects with minimal hassle and
          complication.
        </p>
        <div class="boxes d-flex justify-content-between">
          <div class="box">
            <img src="/img/icons/icons8-time-to-pay-100.png" alt="icon" />
            <h3>Save time, gain focus</h3>
            <p>
              Recruiting and onboarding new technical staff can be challenging. Instead, let us
              assist you in finding, qualifying and building up development capacity on your behalf
              leaving you to focus on your core business.
            </p>
          </div>

          <div class="box">
            <img src="/img/icons/icons8-trophy-100.png" alt="icon" />
            <h3>Vetted, certified and proficient</h3>
            <p>
              All candidates that we present have passed through an extensive qualification process,
              allowing you to pick from a pool of highly capable professionals.
            </p>
          </div>

          <div class="box">
            <img src="/img/icons/icons8-location-100.png" alt="icon" />
            <h3>Closer than you think</h3>
            <p>
              Our nearshoring team is based in Sofia, Bulgaria – placing your resource in a European
              time zone and within easy reach.
            </p>
          </div>

          <div class="box">
            <img src="/img/icons/icons8-home-office-100.png" alt="icon" />
            <h3>Infrastructure in place</h3>
            <p>
              Your nearshore development team will work out of our comfortable office, using modern
              equipment in an enjoyable working environment.
            </p>
          </div>

          <div class="box">
            <img src="/img/icons/icons8-heart-puzzle-100.png" alt="icon" />
            <h3>We have it all covered</h3>
            <p>
              You’ll have access to our local management to oversee the work in a joint capacity
              with you, as well as a local HR team to look after and scale your team as required.
            </p>
          </div>
        </div>
        <div class="whitepaper">
          <h3>Nearshoring software development whitepaper</h3>
          <p>
            Take advantage of nearshoring development to support and enhance your operations.
            Whether you need just one or two new staff or an entire team of developers, QA
            specialists and project managers, we’ll help you identify the necessary roles and fill
            them with the most suitable people. Everything we offer has been designed to make things
            easier for our clients, with suggested agile methodologies to build your projects with
            minimal hassle and complication.
          </p>
          <div class="text-center">
            <a
              href="https://comstream.eu/whitepapers/Nearshore_software_development.pdf"
              class="btn btn-primary"
              download
              >Download Whitepaper</a
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="background-mid-section d-flex align-items-center"
      style="
        background: url('/img/nearshore/nearshorе-development-bg.jpg');
        background-size: cover;
        background-position: bottom;
        margin: 20px 0 0 0;
      "
    >
      <div class="viewport">
        <h3 style="color: #000">
          Are you considering to outsource your entire technical support centre?
        </h3>
        <p style="color: #000">
          Providing high-class technical support to your customers can be a material competitive
          advantage. Find out more about our nearshore support centre model with possibility for
          24/7 availability.
        </p>
        <router-link to="/services/outsourced-support" class="btn btn-primary"
          >Read more</router-link
        >
      </div>
      <div class="overlay" style="background: rgba(255, 255, 255, 0.5)"></div>
    </div>
  </div>
</template>

<script>
import titleMixin from '../mixins/titleMixin';
export default {
  mixins: [titleMixin],
  title: 'Nearshore Software Development | Comstream',
  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
      console.log('deba');
    }, 500);
  },
};
</script>

<style lang="scss" scoped>
.ns-dev {
  .content {
    padding: 70px 0;
    .boxes {
      .box {
        width: 19%;
        img {
          width: 100px;
          height: 100px;
          display: block;
          margin: 12px auto;
        }
        h3 {
          font-size: 24px;
          font-weight: 700;
          text-align: center;
          margin: 0 0px 12px 0;
          color: #000;
        }
        p {
          font-size: 16px;
        }
      }
    }
    .whitepaper {
      padding: 70px 0;
      h3 {
        font-size: 38px;
        text-align: center;
        font-weight: 700;
        padding: 0 10%;
        margin-bottom: 25px;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .ns-dev {
    .content {
      .boxes {
        display: block !important;
        .box {
          width: 100%;
        }
      }
      .whitepaper {
        h3 {
          font-size: 1.8rem;
          padding: 0 !important;
        }
      }
    }
  }
}
</style>
